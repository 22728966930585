import React from "react";

const Help = () => {
  return (
    <div
      style={{
        background: "#D9D9D9",
        borderRadius: "50%",
        width: "44px",
        height: "44px",
        color: "black",
        textAlign: "center",
        lineHeight: "44px",
        verticalAlign: "middle",
        margin: "0px 16px",
      }}
    >
      <div>
        ?
      </div>
    </div>
  );
};

export default Help;
